import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useSanityOrganization,
  useSettings,
} from '@guider-global/sanity-hooks';
import { TOTPSurvey } from '@guider-global/shared-types';
import { Avatar, Header, theme } from '@guider-global/ui';
import { ThemeProvider, useTheme } from '@mui/system';
import { PageWrapper } from 'components';
import { OTPSurveyContainer } from 'containers/Surveys/OTPSurveyContainer';
import { useOTPSurveys } from 'hooks/useOTPSurveys';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { hideNavbar, showNavbar } from 'store/slices/appSlice';
import { deepMerge } from 'utils';
import { SurveyErrorView } from 'views';

export function OTPSurveyPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId');
  const localeCode = searchParams.get('locale') ?? 'en_GB';
  const organizationSlug = getSubDomain();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hideNavbar(true));
    return () => {
      dispatch(showNavbar(true));
    };
  }, [dispatch]);

  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    localeCode,
  });

  const { settings } = useSettings({
    localeCode,
  });

  const organization = getOrganization();

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const { getResponse, reqOTPSurveys } = useOTPSurveys({
    waitForAuthentication: false,
  });

  useEffect(() => {
    reqOTPSurveys({
      url: `/otp/surveys/${id}?userId=${userId}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = getResponse();

  const [otpSurvey] = (result?.data?.data as TOTPSurvey[]) ?? [];

  if (!result) {
    return <></>;
  }

  const errorSettings =
    result.data.code === 409
      ? settings?.guider_surveys?.survey_already_submitted_state
      : settings?.guider_surveys?.survey_not_found_state;

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <Header
        logo={
          <Avatar
            size="large"
            hasBorder={false}
            sx={{ objectFit: 'contain !important' }}
            src={buildSanityImageUrl({
              source: organization.white_label.auth0_logo,
            })}
          />
        }
      />
      <PageWrapper>
        {result.data.status === 'error' ||
        otpSurvey.organizationSlug !== organizationSlug ? (
          <SurveyErrorView
            title={errorSettings?.title}
            description={errorSettings?.description}
            buttonLabel={errorSettings?.return_to_the_platform_button_label}
          />
        ) : (
          <OTPSurveyContainer survey={otpSurvey} settings={settings} />
        )}
      </PageWrapper>
    </ThemeProvider>
  );
}
