import { useLocalization, useProfiles } from 'hooks';
import { ParticipationView } from 'views/Registration';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { Button, Stack } from '@guider-global/ui';
import { ProgressContainer } from 'containers';

export function ParticipationPage() {
  const [checkedMentor, setCheckedMentor] = useState(false);
  const [checkedMentee, setCheckedMentee] = useState(false);
  const navigate = useNavigate();

  // Organization

  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });

  const openMatchingText =
    organization?.open_matching?.program_type?.program_type_text?.common
      ?.open_matching;

  // Base Language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const participationConfig =
    baseLanguage?.registration?.open_matching?.participation;
  const continueButtonLabel =
    baseLanguage?.globals?.common?.continue_button_label ?? 'Continue';

  // Profiles

  const { getProfiles, reqProfiles, isLoadingProfiles } = useProfiles({
    getSilently: false,
    forceRefresh: false,
    getSilentlyUrl: '/profiles',
    queryParams: {
      integrationsEnabled: true,
    },
  });

  const profiles = getProfiles();

  const profile = profiles.at(0);

  useEffect(() => {
    if (profile?.roles) {
      setCheckedMentor(profile.roles.includes('guide'));
      setCheckedMentee(profile.roles.includes('trainee'));
    }
  }, [profile?.roles]);

  async function handleParticipation() {
    if (!profile) {
      return;
    }

    const roles: ('guide' | 'trainee')[] = [];

    if (checkedMentee) {
      roles.push('trainee');
    }
    if (checkedMentor) {
      roles.push('guide');
    }

    try {
      await reqProfiles({
        method: 'PATCH',
        url: `/profiles/${profile.id}`,
        data: {
          roles,
        },
      });
      await reqProfiles({
        url: '/profiles',
      });

      navigate('../goals');
    } catch (e) {
      console.log(e);
    }
  }

  if (!baseLanguage)
    return (
      <ProgressContainer>
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
        pt={5}
      >
        <ParticipationView
          title={participationConfig?.title}
          alertLabel={participationConfig?.alert_label}
          guideText={openMatchingText?.guide_participation}
          traineeText={openMatchingText?.trainee_participation}
          checkedMentor={checkedMentor}
          checkedMentee={checkedMentee}
          setCheckedMentor={setCheckedMentor}
          setCheckedMentee={setCheckedMentee}
        />
        <Button
          variant="contained"
          label={continueButtonLabel}
          color="info"
          onClick={handleParticipation}
          disabled={!checkedMentor && !checkedMentee}
          loading={isLoadingProfiles()}
          data-cy="register-participation-continue-button"
        />
      </Stack>
    </ProgressContainer>
  );
}
