import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { AvatarBox, Button, Divider } from '@guider-global/ui';
import { Stack } from '@mui/system';
import { ProgressContainer } from 'containers';
import { interpolate } from 'functions';
import { useLocalization, useProfiles } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import traineeIcon from 'assets/learn.svg';
import guideIcon from 'assets/users.svg';
import GoalCategoriesView from 'views/Registration/GoalCategoriesView';
import { IGoalCategory } from '@guider-global/shared-types';

export function GoalsPage() {
  const [traineeGoalCategories, setTraineeGoalCategories] = useState<string[]>(
    [],
  );
  const [guideGoalCategories, setGuideGoalCategories] = useState<string[]>([]);

  const navigate = useNavigate();
  const organizationSlug = getSubDomain();

  const { organization } = useOrganization({
    organizationSlug,
  });

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { getProfiles, reqProfiles, isLoadingProfiles } = useProfiles({
    getSilently: false,
    forceRefresh: false,
    getSilentlyUrl: '/profiles',
    queryParams: {
      integrationsEnabled: true,
    },
  });

  const profiles = getProfiles();

  const profile = profiles.at(0);

  useEffect(() => {
    if (profile?.goalCategories) {
      setGuideGoalCategories(
        profile.goalCategories
          ?.filter((goalCategory: any) => goalCategory.offered)
          ?.map((goalCategory: any) => goalCategory.fieldSlug) ?? [],
      );
      setTraineeGoalCategories(
        profile.goalCategories
          ?.filter((goalCategory: any) => goalCategory.sought)
          ?.map((goalCategory: any) => goalCategory.fieldSlug) ?? [],
      );
    }
  }, [profile?.goalCategories]);

  const goalCategories = organization?.goal_categories?.categories?.map(
    (goalCategory) => ({
      label: goalCategory.goal_category_name,
      slug: goalCategory.goal_category_slug.current,
    }),
  );

  const isGuide = profile?.roles?.includes('guide');
  const isTrainee = profile?.roles?.includes('trainee');

  async function handleGoalsSubmit() {
    if (!profile) return;
    const goalCategoriesTraineeData: Partial<IGoalCategory> & {
      goalCategorySlugs: string[];
      role: 'trainee';
    } = {
      goalCategorySlugs: traineeGoalCategories,
      role: 'trainee',
    };
    const goalCategoriesGuideData: Partial<IGoalCategory> & {
      goalCategorySlugs: string[];
      role: 'guide';
    } = {
      goalCategorySlugs: guideGoalCategories,
      role: 'guide',
    };

    //TODO Is this a possible race condition?
    const [result] = await Promise.all([
      ...(isTrainee
        ? [
            await reqProfiles({
              method: 'PATCH',
              url: `/profiles/${profile.id}`,
              data: goalCategoriesTraineeData,
            }),
          ]
        : []),
      ...(isGuide
        ? [
            await reqProfiles({
              method: 'PATCH',
              url: `/profiles/${profile.id}`,
              data: goalCategoriesGuideData,
            }),
          ]
        : []),
    ]);

    if (result.status === 'success' && result.data) {
      if (profile.roles?.includes('guide')) {
        navigate('../skills/guide');
      } else {
        navigate('../skills/trainee');
      }
    }
  }

  const disableContinueButton =
    (isGuide && guideGoalCategories.length === 0) ||
    (isTrainee && traineeGoalCategories.length === 0);

  if (!baseLanguage)
    return (
      <ProgressContainer>
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
      >
        {isGuide && (
          <>
            <AvatarBox
              orientation="vertical"
              textSpacing={1.5}
              size="medium"
              heading={{
                variant: 'h6',
                text: interpolate(
                  baseLanguage?.registration?.open_matching?.common?.preheader,
                  {
                    roleSingular:
                      organization?.open_matching.program_type.program_type_text
                        .common?.guide?.singular ?? 'Mentor',
                  },
                ),
              }}
              subtitles={[
                {
                  text: interpolate(
                    baseLanguage?.registration?.open_matching?.goals
                      ?.title_guide,
                    {
                      traineeSingular:
                        organization?.open_matching.program_type
                          .program_type_text.common?.trainee?.singular ??
                        'Mentee',
                    },
                  ),
                  variant: 'h2',
                  lineHeight: '115%',
                },
                {
                  text:
                    organization?.goal_categories
                      ?.select_goals_custom_description ??
                    baseLanguage?.registration?.open_matching?.goals
                      ?.select_number_of_goals ??
                    'You can select as many as you’d like.',
                  color: 'text.secondary',
                  variant: 'subtitle1',
                },
              ]}
              avatarSrc={guideIcon}
              avatarSx={{
                p: 1.25,
                border: 'none',
                backgroundColor: '#F5F5F5',
              }}
            />
            {goalCategories && (
              <GoalCategoriesView
                key={'guide-goals'}
                goalCategories={goalCategories}
                onSelected={(slugs) => setGuideGoalCategories(slugs)}
                buttonLabels={{
                  more: baseLanguage?.globals?.common?.show_more_button_label,
                  less: baseLanguage?.globals?.common?.show_less_button_label,
                }}
                selectedGoalCategories={guideGoalCategories}
              />
            )}
          </>
        )}
        {isGuide && isTrainee && <Divider sx={{ mb: 3 }} />}
        {isTrainee && (
          <>
            <AvatarBox
              orientation="vertical"
              textSpacing={1.5}
              size="medium"
              heading={{
                variant: 'h6',
                text: interpolate(
                  baseLanguage?.registration?.open_matching?.common?.preheader,
                  {
                    roleSingular:
                      organization?.open_matching.program_type.program_type_text
                        .common?.trainee?.singular ?? 'Mentee',
                  },
                ),
              }}
              subtitles={[
                {
                  text: interpolate(
                    baseLanguage?.registration?.open_matching?.goals
                      ?.title_trainee,
                    {
                      guideSingular:
                        organization?.open_matching.program_type
                          .program_type_text.common?.guide?.singular ??
                        'Mentor',
                    },
                  ),
                  variant: 'h2',
                  lineHeight: '115%',
                },
                {
                  text:
                    organization?.goal_categories
                      ?.select_goals_custom_description ??
                    baseLanguage?.registration?.open_matching?.goals
                      ?.select_number_of_goals ??
                    'You can select as many as you’d like.',
                  color: 'text.secondary',
                  variant: 'subtitle1',
                },
              ]}
              avatarSrc={traineeIcon}
              avatarSx={{
                p: 1.25,
                border: 'none',
                backgroundColor: '#F5F5F5',
              }}
            />
            {goalCategories && (
              <GoalCategoriesView
                key={'trainee-goals'}
                goalCategories={goalCategories}
                onSelected={(slugs) => setTraineeGoalCategories(slugs)}
                buttonLabels={{
                  more: baseLanguage?.globals?.common?.show_more_button_label,
                  less: baseLanguage?.globals?.common?.show_less_button_label,
                }}
                selectedGoalCategories={traineeGoalCategories}
              />
            )}
          </>
        )}
        <Button
          variant="contained"
          label={baseLanguage?.globals?.common?.continue_button_label}
          color="info"
          onClick={() => handleGoalsSubmit()}
          disabled={disableContinueButton}
          loading={isLoadingProfiles()}
          data-cy="register-goals-continue-button"
        />
      </Stack>
    </ProgressContainer>
  );
}
