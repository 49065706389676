import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LegalNotices from 'components/legalNotices';
import { INavbarLink } from 'containers';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useAppDispatch } from 'store/hooks';
import { setNavbarUnauthenticatedActions } from 'store/slices/appSlice';

const LegalPageTerms: FC = () => {
  // Route
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();

  // Auth0
  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);
  const handleRegister = useCallback(
    () => navigate('/register/account'),
    [navigate],
  );

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const { legal_notices, navigation: baseLanguageNavigation } =
    getBaseLanguage();

  useEffect(() => {
    if (!baseLanguageNavigation) return;
    const organizationUnauthenticatedActions: INavbarLink[] = [
      {
        label: baseLanguageNavigation?.login_button_label,
        action: handleLogin,
        type: 'button',
        variant: 'outlined',
        dataCy: 'navbar-login-button',
      },
      {
        label: baseLanguageNavigation?.register_button_label,
        action: handleRegister,
        type: 'button',
        variant: 'contained',
        color: 'info',
        dataCy: 'navbar-unauthenticated-register-button',
      },
    ];

    dispatch(
      setNavbarUnauthenticatedActions(organizationUnauthenticatedActions),
    );
  }, [baseLanguageNavigation, dispatch, handleLogin, handleRegister]);

  const termsOfServiceContent =
    legal_notices?.terms_of_service?.terms_of_service_content ?? [];

  const termsOfServiceLabel =
    legal_notices?.terms_of_service?.terms_of_service_label;

  return (
    <>
      <LegalNotices
        title={termsOfServiceLabel}
        content={termsOfServiceContent}
      />
    </>
  );
};

export default LegalPageTerms;
