import { ProgressContainer } from 'containers';
import { OnboardingForm } from 'forms/OnboardingForm';
import { useProfiles } from 'hooks';

export function CreateProfilePage() {
  const { getProfiles } = useProfiles({
    getSilently: false,
    getSilentlyUrl: '/profiles',
    queryParams: {
      integrationsEnabled: true,
    },
  });

  const profiles = getProfiles();

  const profile = profiles.at(0);

  return (
    <ProgressContainer>
      <OnboardingForm profile={profile} />
    </ProgressContainer>
  );
}
