import { useAuth } from '@guider-global/auth-hooks';
import {
  getCountryList,
  getOrigin,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  useOrganization,
  useSanityBaseLanguage,
} from '@guider-global/sanity-hooks';
import {
  ICustomField,
  SanityOrganization,
  SanityTextBlock,
} from '@guider-global/shared-types';
import {
  Stack,
  Button,
  TextStack,
  FormTextArea,
  FormSelectInput,
  FormPhoneNumber,
  DropdownItemProps,
  LinkCheckbox,
  FormMultiSelectInput,
  FormPhoneNumberValue,
  TextareaInputValue,
  SelectareaInputValue,
  MultiSelectAreaInputValue,
  DateFieldValue,
  CheckboxTextItemProps,
  ContentModal,
} from '@guider-global/ui';
import { Box } from '@mui/system';
import { PortableText } from '@portabletext/react';
import { LoadingElement } from 'components';
import { Markdown } from 'components/Markdown';
import { ProgressContainer } from 'containers';
import { interpolate } from 'functions';
import { useCustomFields, useMixpanelEvents, useProfiles } from 'hooks';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showAppAlert } from 'store/slices/appSlice';
import {
  MissingAgreementField,
  MissingProfileField,
  getIncompleteProfileFields,
} from 'utils';

type CompleteProfileFormValues = {
  [key: string]:
    | FormPhoneNumberValue
    | TextareaInputValue
    | SelectareaInputValue
    | MultiSelectAreaInputValue
    | { fieldType: 'check'; value: string; name?: string }
    | DateFieldValue;
};

export const CompleteProfilePage = () => {
  const navigate = useNavigate();
  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [checkedOrganizationAgreements, setCheckedOrganizationAgreements] =
    useState<boolean>(false);
  const [isLoadingMissingFields, setIsLoadingMissingFields] =
    useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<
    SanityTextBlock[] | string
  >();

  // Hooks
  const { resetUserIdentity } = useMixpanelEvents();
  const { logout } = useAuth({ waitForAuthentication: true });
  const dispatch = useDispatch();

  // Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const sanityBaseLanguageOnboarding = baseLanguage.onboarding;
  const newProfileFields = sanityBaseLanguageOnboarding?.new_profile_fields;

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });
  const personalDetails = organization?.personal_details;
  const profileFieldText = baseLanguage.profile_fields;
  let formattedAgreements: CheckboxTextItemProps[] = [];
  let organizationName = organization?.basic_info.name;
  if (organizationName?.charAt(organizationName.length - 1) === 's') {
    organizationName = organizationName + "'";
  } else {
    organizationName = organizationName + "'s";
  }

  // Custom fields
  const { reqCustomFields } = useCustomFields({
    getSilently: false,
    forceRefresh: false,
  });

  // Profile
  const { getProfiles, reqProfiles } = useProfiles({});
  const profiles = getProfiles();
  const profile = profiles.at(0);

  const incompleteFields = useMemo(() => {
    if (!profile) return;
    setIsLoadingMissingFields(true);
    const fields = getIncompleteProfileFields({
      profile,
      sanityOrganization: organization,
      organizationAgreements: organization?.white_label.additional_agreements,
    });

    setIsLoadingMissingFields(false);

    return fields ?? [];
  }, []);

  // Logout
  const handleLogout = async () => {
    try {
      await logout({ logoutParams: { returnTo: getOrigin() } });
      resetUserIdentity();
    } catch (err) {
      console.error(err);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<CompleteProfileFormValues>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<CompleteProfileFormValues> = async (
    data: CompleteProfileFormValues,
  ) => {
    setLoading(true);
    if (!profile) return;
    const {
      jobTitle,
      linkedInUrl,
      townOrCity,
      country,
      pronouns,
      gender,
      dateOfBirth,
      phoneNumber,
      ...customFields
    } = data;

    let dateOfBirthString;
    if (dateOfBirth && dateOfBirth?.value !== '') {
      dateOfBirthString =
        (dateOfBirth.value as Date).toISOString().split('T')[0] ?? '';
    }

    const profileData = {
      country: (country?.value as string) ?? undefined,
      phoneNumber: (phoneNumber?.value as string) ?? undefined,
      jobTitle: (jobTitle?.value as string) ?? undefined,
      townOrCity: (townOrCity?.value as string) ?? undefined,
      pronouns: (pronouns?.value as string) ?? undefined,
      gender: (gender?.value as string) ?? undefined,
      dateOfBirth: dateOfBirthString,
      linkedInUrl: (linkedInUrl?.value as string) ?? undefined,
    };

    const profilesResult = await reqProfiles({
      method: 'PATCH',
      url: `/profiles/${profile.id}`,
      data: profileData,
    });

    if (profilesResult.status === 'success' && profilesResult.data) {
      const customFieldsData = Object.values(customFields)
        .filter((field) => field.name)
        .map(({ fieldType, value, name }) => {
          return {
            fieldSlug: name,
            organizationSlug,
            fieldType,
            value,
            profileId: profile.id,
          };
        });

      missingAgreements?.forEach(({ agreementData: agreement }) => {
        const regex = /[^A-Za-z0-9]/g;
        const validatedAgreementName =
          agreement?.agreement_name?.current.replaceAll(regex, '');

        customFieldsData.push({
          fieldSlug: `${validatedAgreementName}-${agreement?._key}`,
          organizationSlug,
          fieldType: 'check',
          value: 'true',
          profileId: profile.id,
        });
      });

      const customFieldsResult = await reqCustomFields({
        method: 'POST',
        url: `/customfields`,
        data: [...customFieldsData] as ICustomField[],
      });

      if (customFieldsResult.status === 'error') {
        dispatch(
          showAppAlert({
            severity: 'error',
            message: customFieldsResult.message,
            timeout: 2000,
          }),
        );
        setLoading(false);
      } else {
        navigate('/dashboard');
        setLoading(false);
      }
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: 'Error creating profile',
          timeout: 2000,
        }),
      );
    }
    setLoading(false);
  };
  const genders: DropdownItemProps[] = Object.entries(
    profileFieldText?.gender?.options ?? {},
  ).map(([key, label]) => {
    return {
      key,
      value: key,
      label,
    };
  });

  if (isLoadingMissingFields)
    return (
      <ProgressContainer>
        <Stack
          direction={'column'}
          justifyContent={'flex-start'}
          width={{ xs: '90%', md: '480px' }}
          pb={4}
          pt={{ xs: 4, md: 2 }}
        >
          <LoadingElement />
        </Stack>
      </ProgressContainer>
    );

  if (!incompleteFields) return <></>;

  const missingAgreements = incompleteFields.filter(
    (field) => typeof field === 'object' && field.type === 'agreement',
  ) as MissingAgreementField[];

  const missingProfileFields = incompleteFields.filter(
    (field) => typeof field === 'object' && field.type !== 'agreement',
  ) as MissingProfileField[];

  const missingPersonalFields = incompleteFields.filter(
    (field) => typeof field === 'string',
  ) as string[];

  // Agreements
  function handleModalOpen(title: string, content: SanityTextBlock[] | string) {
    setModalOpen(true);
    setModalContent(content);
    setModalTitle(title);
  }

  function closeModal() {
    setModalOpen(false);
    setModalContent(undefined);
    setModalTitle('');
  }

  missingAgreements.forEach(({ agreementData: agreement }, index) => {
    if (missingAgreements && agreement) {
      if (
        index === missingAgreements?.length - 1 &&
        missingAgreements?.length > 1
      ) {
        formattedAgreements.push({ text: ' & ' });
        formattedAgreements.push({
          text: agreement.name,
          onClick: () => handleModalOpen(agreement.name, agreement.content),
        });
        return;
      } else {
        formattedAgreements.push({
          text: agreement.name,
          onClick: () => handleModalOpen(agreement.name, agreement.content),
        });
        return;
      }
    }
    return undefined;
  });

  // Countries
  const countryList: DropdownItemProps[] = getCountryList({
    countryCode: 'GB',
    languageCode: 'en',
  }).map(({ key, countryName }) => ({
    key,
    label: countryName,
    value: key,
  }));

  return (
    <ProgressContainer>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
        pb={4}
        pt={{ xs: 4, md: 2 }}
      >
        <TextStack
          size={'large'}
          heading={newProfileFields?.title}
          subtitles={[newProfileFields?.description]}
        />
        <Stack
          component={'form'}
          direction={'column'}
          onSubmit={handleSubmit(onSubmit)}
          gap={1}
        >
          {missingPersonalFields.includes('pronouns') && (
            <FormTextArea
              control={control}
              rows={1}
              label={
                personalDetails?.pronouns.custom_label ??
                profileFieldText?.pronouns_input_label
              }
              labelSize="xs"
              placeholder={
                personalDetails?.pronouns.custom_label ??
                profileFieldText?.pronouns_input_label
              }
              name="pronouns"
              errorMessage={baseLanguage.globals?.errors?.required_field}
            />
          )}
          {missingPersonalFields.includes('gender') && (
            <FormSelectInput
              control={control}
              label={
                personalDetails?.gender.custom_label ??
                profileFieldText?.gender?.gender_input_label
              }
              name="gender"
              options={genders}
              placeholder={
                personalDetails?.gender.custom_label ??
                profileFieldText?.gender?.gender_input_label
              }
              labelSize="xs"
              errorMessage={baseLanguage.globals?.errors?.required_field}
            />
          )}
          {missingPersonalFields.includes('jobTitle') && (
            <FormTextArea
              control={control}
              rows={1}
              label={
                personalDetails?.job_title.custom_label ??
                profileFieldText?.job_title_input_label
              }
              labelSize="xs"
              placeholder={
                personalDetails?.job_title.custom_label ??
                profileFieldText?.job_title_input_label
              }
              name="jobTitle"
              errorMessage={baseLanguage.globals?.errors?.required_field}
            />
          )}
          {missingPersonalFields.includes('linkedInUrl') && (
            <FormTextArea
              control={control}
              rows={1}
              label={
                personalDetails?.linkedin_url.custom_label ??
                profileFieldText?.linkedin_url?.input_label
              }
              labelSize="xs"
              placeholder={
                personalDetails?.linkedin_url.custom_label ??
                profileFieldText?.linkedin_url?.input_label
              }
              name="linkedInUrl"
              errorMessage={baseLanguage.globals?.errors?.required_field}
              type="url"
              validationErrorMessage={interpolate(
                baseLanguage.globals?.errors?.validation_error_blank,
                {
                  fieldName:
                    personalDetails?.linkedin_url.custom_label ??
                    profileFieldText?.linkedin_url?.input_label,
                },
              )}
            />
          )}
          {missingPersonalFields.includes('phoneNumber') && (
            <FormPhoneNumber
              control={control}
              name="phoneNumber"
              label={profileFieldText?.phone_number_input_label}
              placeholder={profileFieldText?.phone_number_input_label}
              validationErrorMessage={interpolate(
                baseLanguage.globals?.errors?.validation_error_blank,
                {
                  fieldName: profileFieldText?.phone_number_input_label ?? '',
                },
              )}
              initialCountry="gb"
            />
          )}
          {missingPersonalFields.includes('townOrCity') && (
            <FormTextArea
              control={control}
              rows={1}
              label={personalDetails?.town_or_city.custom_label}
              labelSize="xs"
              placeholder={
                personalDetails?.town_or_city.custom_label ??
                profileFieldText?.town_or_city_input_label
              }
              name="townOrCity"
              errorMessage={baseLanguage.globals?.errors?.required_field}
            />
          )}
          {missingPersonalFields.includes('country') && (
            <FormSelectInput
              control={control}
              label={
                personalDetails?.country.custom_label ??
                profileFieldText?.country?.country_input_label
              }
              name="country"
              options={countryList}
              placeholder={
                personalDetails?.country.custom_label ??
                profileFieldText?.country?.country_input_label
              }
              labelSize="xs"
              errorMessage={baseLanguage?.globals?.errors?.required_field}
            />
          )}
          {missingProfileFields.map(({ type, fieldData: field }) => {
            const fieldOptions: DropdownItemProps[] | undefined =
              field?.options?.map((option: any) => {
                return {
                  key: option.id.current,
                  value: option.id.current,
                  label: option.label,
                };
              });

            if (type === 'free-text' && field) {
              return (
                <FormTextArea
                  tooltipText={field.text.assistive_text}
                  required={field.required}
                  key={field.id.current}
                  control={control}
                  rows={
                    field.free_text_config?.text_area_size === 'singleline'
                      ? 1
                      : 7
                  }
                  label={field.text.label}
                  labelSize="xs"
                  placeholder={field.text.label}
                  name={field.id.current}
                  errorMessage={baseLanguage.globals?.errors?.required_field}
                />
              );
            } else if (type === 'select' && field && fieldOptions) {
              return (
                <FormSelectInput
                  tooltipText={field.text.assistive_text}
                  required={field.required}
                  key={field.id.current}
                  control={control}
                  label={field.text.label}
                  name={field.id.current}
                  options={fieldOptions}
                  placeholder={field.text.label}
                  labelSize="xs"
                  errorMessage={baseLanguage.globals?.errors?.required_field}
                />
              );
            } else if (type === 'multi-select' && field && fieldOptions) {
              return (
                <FormMultiSelectInput
                  tooltipText={field.text.assistive_text}
                  required={field.required}
                  key={field.id.current}
                  control={control}
                  label={field.text.label}
                  name={field.id.current}
                  options={fieldOptions}
                  placeholder={field.text.label}
                  labelSize="xs"
                  errorMessage={baseLanguage.globals?.errors?.required_field}
                />
              );
            } else {
              return <></>;
            }
          })}
          {missingAgreements?.length !== 0 && (
            <LinkCheckbox
              isChecked={checkedOrganizationAgreements}
              onChange={() =>
                setCheckedOrganizationAgreements(!checkedOrganizationAgreements)
              }
              text={[
                {
                  text:
                    baseLanguage.legal_notices?.i_agree_to_legals_label +
                    ' ' +
                    organizationName,
                },
                ...formattedAgreements,
              ]}
            />
          )}
          <Button
            loading={loading}
            variant="contained"
            label={baseLanguage.globals?.common?.continue_button_label}
            color="info"
            type="submit"
            disabled={
              !isValid ||
              (missingAgreements.length !== 0 && !checkedOrganizationAgreements)
            }
          />
        </Stack>
        <Button variant="text" onClick={handleLogout} color="error">
          {newProfileFields?.logout_button_label}
        </Button>
      </Stack>
      <ContentModal
        open={modalOpen}
        onClose={closeModal}
        heading={modalTitle}
        primaryButtonProps={{
          label: baseLanguage.globals?.common?.close_button_label,
          onClick: closeModal,
          color: 'info',
        }}
        paperSx={{
          width: { sm: '100%', md: '600px' },
          mx: { sm: 0, md: '' },
        }}
      >
        <Box px={2}>
          {typeof modalContent === 'string' ? (
            <Markdown>{modalContent}</Markdown>
          ) : (
            <Box component={PortableText} value={modalContent ?? []} />
          )}
        </Box>
      </ContentModal>
    </ProgressContainer>
  );
};
