import { getOrgUrl } from '@guider-global/front-end-utils';
import { Button, TextField } from '@guider-global/ui';
import { Box } from '@mui/material';
import { PageWrapper } from 'components';
import { useState } from 'react';

export const ConfigurePage = () => {
  const [value, setValue] = useState<string>('');
  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  const setSubdomain = () => {
    window.location.href = getOrgUrl(value).toString();
  };

  return (
    <PageWrapper>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <TextField onChange={handleChange} />
        <Button onClick={setSubdomain} sx={{ ml: 2 }}>
          Go
        </Button>
      </Box>
    </PageWrapper>
  );
};
