/* eslint-disable camelcase */
// external
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Button, useScrollTrigger } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { INavbarLink } from 'containers';
// components
import {
  OrganizationLandingPageHero,
  LandingPageContent,
} from 'components/LandingPage';
import { AnimationWrapper } from 'components/AnimationWrapper';

// Hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';

// store
import {
  hideNavbar,
  selectRegistrationType,
  setNavbarUnauthenticatedActions,
} from 'store/slices/appSlice';

// types

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';

export const OrganizationLandingPage: React.FC = () => {
  // Route
  const navigate = useNavigate();
  // Redux

  const dispatch = useAppDispatch();
  const registrationPageType = useAppSelector(selectRegistrationType);
  const showLoginButtons = registrationPageType !== 'none';

  const organizationSlug = getSubDomain();

  // Hooks
  // useSanityOrganization
  const { getOrganization, isSuccessSanityOrganization } =
    useSanityOrganization({ organizationSlug, getSilently: false });
  const sanityOrganization = getOrganization();
  const isSanityOrganizationSuccess = isSuccessSanityOrganization();
  const organizationLandingPage = sanityOrganization.landing_page;
  // useSanityBaseLanguage
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const baseLanguageNavigation = baseLanguage.navigation;

  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);
  const handleRegister = useCallback(
    () => navigate('/register/account'),
    [navigate],
  );

  // Styling
  const isMobile = useMobileMediaQuery();
  const isEnabled = organizationLandingPage?.enable_landing_page;
  const [heroHeight, setHeroHeight] = useState(0);
  const contentRef = useRef<null | HTMLDivElement>(null);

  const heroConfig = useMemo(
    () => organizationLandingPage?.hero,
    [organizationLandingPage],
  );

  const bottomButtonTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: heroHeight,
  });

  useEffect(() => {
    if (!baseLanguageNavigation) return;
    const organizationUnauthenticatedActions: INavbarLink[] = [
      {
        label: baseLanguageNavigation?.login_button_label,
        action: handleLogin,
        type: 'button',
        variant: 'outlined',
        dataCy: 'navbar-login-button',
      },
      {
        label: baseLanguageNavigation?.register_button_label,
        action: handleRegister,
        type: 'button',
        variant: 'contained',
        color: 'info',
        dataCy: 'navbar-unauthenticated-register-button',
      },
    ];
    dispatch(
      setNavbarUnauthenticatedActions(organizationUnauthenticatedActions),
    );
  }, [baseLanguageNavigation, dispatch, handleLogin, handleRegister]);

  useEffect(() => {
    if (!isEnabled && isSanityOrganizationSuccess) {
      dispatch(hideNavbar(true));
      if (
        registrationPageType === 'localAccounts' ||
        registrationPageType === 'localAccountsAndSSO'
      ) {
        handleRegister();
      } else {
        handleLogin();
      }
    }
  }, [
    dispatch,
    handleLogin,
    handleRegister,
    isEnabled,
    isSanityOrganizationSuccess,
    registrationPageType,
  ]);

  const shouldShowBottomButton = isMobile && bottomButtonTrigger;

  const handleLearnMoreClick = () => {
    contentRef?.current &&
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (!isEnabled) {
    return <></>;
  }

  return (
    <Box sx={{ backgroundColor: '#ffffff' }}>
      {heroConfig && (
        <OrganizationLandingPageHero
          config={heroConfig}
          handleLearnMoreClick={handleLearnMoreClick}
          setHeroHeight={setHeroHeight}
        />
      )}

      <LandingPageContent
        config={organizationLandingPage?.content}
        onClickRegisterButton={handleRegister}
        ref={contentRef}
      />
      {isMobile && heroConfig && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'sticky',
            bottom: '0',
            zIndex: 10,
          }}
        >
          <AnimationWrapper
            externalTrigger={shouldShowBottomButton}
            slideDirection="up"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, .3)',
              backdropFilter: 'blur(10px)',
              p: 2,
            }}
          >
            {showLoginButtons && (
              <Button
                data-cy="pages_LandingPage_OrganisationLandingPage_login-button"
                variant="contained"
                size="large"
                color="info"
                sx={{ width: '500px', maxWidth: '100%' }}
                onClick={handleLogin}
              >
                {heroConfig.button_1_label}
              </Button>
            )}
          </AnimationWrapper>
        </Box>
      )}
    </Box>
  );
};
